@font-face {
  font-family: 'IBM Plex Sans';
  src: url('./app/assets/fonts/IBMPlexSans-Regular.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'IBMPlexSans';
  src: url('./app/assets/fonts/IBMPlexSans-Regular.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'IBMPlexSans-Regular';
  src: url('./app/assets/fonts/IBMPlexSans-Regular.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'IBMPlexSans-Bold';
  src: url('./app/assets/fonts/IBMPlexSans-Bold.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'IBMPlexSans-Medium';
  src: url('./app/assets/fonts/IBMPlexSans-Medium.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Inter-Light';
  src: url('./app/assets/fonts/Inter-Light.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Inter-Regular';
  src: url('./app/assets/fonts/Inter-Regular.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Inter-Medium';
  src: url('./app/assets/fonts/Inter-Medium.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./app/assets/fonts/Inter-SemiBold.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Inter-Bold';
  src: url('./app/assets/fonts/Inter-Bold.ttf'); /* IE9 Compat Modes */
}
body {
  font-family: Inter;
  /* overflow: hidden; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
textarea:focus,
input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
